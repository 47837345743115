import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { DatePicker, Button } from 'antd';
import Button from 'antd/lib/button';

// import images
import logo_footer from '../../images/logo_footer.png';
import icon_fb_normal from '../../images/icon_fb_normal.png';
import icon_line_normal from '../../images/icon_line_normal.png';
import btn_top from '../../images/btn_TOP.png';
import line_QR_code from '../../images/cheftenglineQRcode.png';


class ScrollButton extends React.Component {
  constructor() {
    super();
    this.state = {
      intervalId: 0
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
      <div className="btn_top" onClick={() => this.scrollToTop()}>
        <img src={btn_top} alt="" />
      </div>
    )
  }
}

class FooterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="footerAll">
        {/*
        <button className="btn_top" type="button">
          <img src={btn_top} alt="" />
        </button>
        */}

        <ScrollButton scrollStepInPx="50" delayInMs="5" />

        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img className="footerLogo rounded mx-auto d-block" src={logo_footer} alt="鄧師傅標誌" />
            </div>
            <div className="footerText">
              <div className="row footerNav">
                <a href='/aboutUs'>關於我們</a>
                <a href='/story'>品牌故事</a>
                <a href='/news'>媒體報導</a>
                <a href='/memberInfo'>會員須知</a>
                <a href='/shoppingNotes'>購物須知</a>
                <a href='/redeemInfo'>集點須知</a>
              </div>
              <div className="row info">
                <div><a href="tel:0800-45-54-45">消費者專線：0800-45-54-45</a></div>
                <div>服務時間：週一至週五 9:00 ~ 17:00</div>
              </div>
              <div className="row info">
                <div>總公司：高雄市新興區中正三路 82 號</div>
                <div>供應商名稱：鄧師傅餐飲事業(博竑食品有限公司)</div>
              </div>
              <div className="row info">
                <div><a href="tel:07-2361822">供應商電話：07-2361822</a></div>
                <div>供應商地址：高雄市大社區旗楠路80巷95號</div>
              </div>
              <div className="row info">
                <div>工廠登記字號：64009322</div>
                <div>投保產品責任險字號：明台產物產品責任保險0804第10PDT00396</div>
              </div>
              {/* <p className="Text_24">鄧師傅餐飲事業 since 1984</p>
              <div className="dividers"></div>
              <p className="Text_18">地址：台灣高雄市800新興區中正三路82號</p>
              <p className="Text_18">電話：0800-45-54-45</p>
              <p className="Text_18">食品業者登錄字號：E-186058536-00000-3</p>
              <div className="FBLINEBOX2">
                <a href="https://www.facebook.com/ChefTengRG/" target="blank"><img src={icon_fb_normal} alt="" /></a>
                <a href="https://line.me/R/ti/p/%40wtz8539j" target="blank"><img src={icon_line_normal} alt="" /></a>
              </div> */}
            </div>
          </div>
        </div>

        <div className="FBLINEBOX">
          <a href="https://www.facebook.com/ChefTengRG/" target="blank"><div className="iconFB"></div></a>
          <a href="https://line.me/R/ti/p/%40wtz8539j" target="blank"><div className="iconLINE"></div></a>
          {/*
          <a data-toggle="collapse" href="#LineQR" aria-expanded="false" aria-controls="LineQR"><div className="iconLINE"></div></a>
          */}
        </div>
        {/*
        <div className="QRArrowBox collapse" id="LineQR">
          <div className="LineQRArrowBox" >
            <img className="img-fluid" src={line_QR_code} alt="" />
          </div>
        </div>
        */}
      </div>
    )
  }
}

export default FooterComponent;
